// Here you can add other styles
.main .container-fluid {
  padding: 30px;
}

.sidebar {
  background: linear-gradient(to right, darken($primary, 5%), lighten($primary, 5%));

  .nav-link {
    .nav-icon {
      color: #fff !important;
    }
  }
}

// Navbar toggler position
.navbar-toggler {
  position: absolute;
  left: 0;

  &, &:focus{
    outline: none;
  }
}

// Calendar
.rbc-calendar {
  height: 500px !important;
}

.rbc-event {
  background: $primary !important;
}

// Footer background
.app-footer {
  background: #fff;
  border: 1px solid #ddd;
}

// Balloon.css
[data-balloon]:before {
  display: none;
}

[data-balloon]:after {
  background: $primary !important;
  margin-bottom: 5px !important;
}

.login-page {
  background-image: url('../assets/img/background.png') !important;
}

.dropdown-menu {
  max-height: 400px;
  overflow: auto;
}

.navbar-brand {
  position: relative;
  z-index: -1;
}